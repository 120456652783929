@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "inter-ui";
        font-weight: 400;
        font-style: normal;
        src: url(./fonts/inter-ui/Inter-UI-Regular.otf) format("otf");
    }
    @font-face {
        font-family: "inter-ui";
        font-weight: 400;
        font-style: italic;
        src: url(./fonts/inter-ui/Inter-UI-Italic.otf) format("otf");
    }
    @font-face {
        font-family: "inter-ui";
        font-weight: 500;
        font-style: normal;
        src: url(./fonts/inter-ui/Inter-UI-Medium.otf) format("otf");
    }
    @font-face {
        font-family: "inter-ui";
        font-weight: 500;
        font-style: italic;
        src: url(./fonts/inter-ui/Inter-UI-MediumItalic.otf) format("otf");
    }
    @font-face {
        font-family: "inter-ui";
        font-weight: 700;
        font-style: normal;
        src: url(./fonts/inter-ui/Inter-UI-Bold.otf) format("otf");
    }
    @font-face {
        font-family: "inter-ui";
        font-weight: 700;
        font-style: italic;
        src: url(./fonts/inter-ui/Inter-UI-BoldItalic.otf) format("otf");
    }
    @font-face {
        font-family: "inter-ui";
        font-weight: 900;
        font-style: normal;
        src: url(./fonts/inter-ui/Inter-UI-Black.otf) format("otf");
    }
    @font-face {
        font-family: "inter-ui";
        font-weight: 900;
        font-style: italic;
        src: url(./fonts/inter-ui/Inter-UI-BlackItalic.otf) format("otf");
    }

    html,
    body {
        @apply w-full h-full overflow-hidden ;
        --toastify-icon-color-success: #74af20;
        background-color: #EAEAEA;
    }

    #root {
        @apply w-full h-full;
    }
}

.shadow-mail:hover {
    box-shadow: 0px 5px 5px rgb(0 0 0 / 0.1), 0px -5px 5px rgb(0 0 0 / 0.1);
}


.container{
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;

  @media (max-width: calc(1440px + 10px) ) {
    max-width: calc(100% - 2.5rem);
  }
}


:root {
  --border: 1px solid #fff;
  --symbol-color: #FF7F5B;
}


.calendar_view{
}

.calendar_view__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar_view__item {
  border-bottom: var(--border);
  border-right: var(--border);

  line-height: 100%;
  @apply flex flex-col items-center p-2;
}

.calendar_view__item:not(:nth-last-child(-n + 5)) {
}

.icon-shadow{
    box-shadow: 0px 0px 4px 1px #b2b2b2;
}


.sidenavbar{
    height: calc(100% - 48px);
}

.sidenavbar::-webkit-scrollbar{
    display: none;
}

.menu_item__label{
    
}

.block_icon_triangle{
    position: relative;
}

.block_icon_triangle::after{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 9px;
    border-color: transparent transparent transparent theme(colors.mr.cyan.400);
}

.calendar_day__settings + .calendar_day__settings{
    margin-top: 1.125rem;
}



.break{
  --max-time: 48;
  --duration: 1;
  --start-time: 12;
  
  position: absolute;
  top: 0;
  left: calc(100% * var(--start-time) / var(--max-time));
  width: calc(100% * (var(--duration)) / var(--max-time));
  height: 100%;
  background-image: linear-gradient(135deg, #3aa2ad 8.33%, #ffffff 8.33%, #ffffff 50%, #3aa2ad 50%, #3aa2ad 58.33%, #ffffff 58.33%, #ffffff 100%);
  background-size: 4px 4px;
  pointer-events: none;
  z-index: 9;
}

.break::after{
  content: 'Pause';
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, 100%);
  color: #3AA2AD;
  font-family: Arial;
  opacity: 0.5;
}

.time-t{
  --max-time: 48;
  --duration: 1;
  --start-time: 12;
  
  position: absolute;
  bottom: -5px;
  left: calc(100% * var(--start-time) / var(--max-time));
  height: 100%;
  pointer-events: none;
  z-index: 10;

 /* content: var(--text-time, 'hello');
  position: absolute;
  bottom: -6px;
  left: 50%;*/
  transform: translate(-50%, 100%);
  color: #3AA2AD;
  font-family: Arial;
}

.time-t::after{
  
}

.time-selector.range-slider {
    /* CSS for the wrapper element */
    background: #AAAAAA;
    height: 10px;
}
.time-selector[data-disabled] {
    /* CSS for disabled range slider element */
}
.time-selector.range-slider .range-slider__range {
    /* CSS for range */
    background: theme(colors.mr.cyan.400);
}
.time-selector .range-slider__range[data-active] {
    /* CSS for active (actively being dragged) range */
}
.time-selector.range-slider .range-slider__thumb {
    background: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 11C22 16.7989 17.2989 21.5 11.5 21.5C5.70114 21.5 1 16.7989 1 11C1 5.20114 5.70114 0.5 11.5 0.5C17.2989 0.5 22 5.20114 22 11Z' fill='white' stroke='%23A9A9A9'/%3E%3Cpath d='M8.5 7V15' stroke='%23AAAAAA' stroke-miterlimit='10'/%3E%3Cpath d='M11.5 7V15' stroke='%23AAAAAA' stroke-miterlimit='10'/%3E%3Cpath d='M14.5 7V15' stroke='%23AAAAAA' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
    z-index: 11;
}
.time-selector .range-slider__thumb[data-lower] {
    /* CSS for lower thumb */
}
.time-selector .range-slider__thumb[data-upper] {
    /* CSS for upper thumb */
}
.time-selector .range-slider__thumb[data-active] {
    /* CSS for active (actively being dragged) thumbs */
}
.time-selector .range-slider__thumb[data-disabled] {
    /* CSS for disabled thumbs */
}


.notifBox{
    background: #FBFFF8;
    border: 1px solid #C4DBA7;
    box-shadow: 0px 0px 4px rgba(50, 127, 0, 0.27);
    border-radius: 5px;
    padding: 12px 24px;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    color: #327F00;
}

.notifBox--orange{
    background: #FFF6EE;
    color: #EA754B;
    border-color: #EFD0B6;
}

.notifBox--red{
    background: #FFF6EE;
    color: #DB3131;
    border-color: #F2C7C7;
}

.selection-area {
    background: rgba(46, 115, 252, 0.11);
    border: 2px solid rgba(98, 155, 255, 0.81);
    border-radius: 0.1em;
}

.container {
    /* user-select: none; */
}


.floating_menu {
    position: absolute;
    top: 0;
    left: 56px;
    height: calc(100% - 48px);
    z-index: 9;
    top: 48px;
}


.floating_menu__layout {
    padding-left: calc(1.25rem + 56px)
}


.calendar__current_week_row{

}

.calendar__current_week_row::before{
    @apply bg-green-400 bg-opacity-20;
    content: '';
    display: block;
    width: 1.5rem;
    height: calc(100% + 2px);
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-100%, -1px);
}


.container{
  margin: 0 auto;
  width: calc(100% - 2.5rem);
  max-width: 1280px;

  @media (max-width: calc(1280px + 10px) ) {
    max-width: calc(100% - 2.5rem);
  }
}

.dropdown {
  display: grid; /* 1 */
  grid-template-rows: 0fr; /* 2 */
  transition: grid-template-rows 0.5s ease-in-out; /* 3 */
  transition: padding-bottom 0.5s ease-in-out; /* 3 */
  transition: padding-top 0.5s ease-in-out; /* 3 */
}

.dropdown.open {
  grid-template-rows: 1fr; /* 5 */
}

.dropdown-inner {
  overflow: hidden; /* 4 */
}

.dropdown.small {
    padding: 1.5rem;
}

.dropdown.default {
    padding: 3rem;
}

.TableContent {
  scrollbar-width: thin;
  scrollbar-color: var(--highlight-background) transparent;

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
}