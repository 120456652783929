.react-aria-Tabs {
    display: flex;
    color: var(--text-color);
  
    &[data-orientation=horizontal] {
      flex-direction: column;
    }
  }
  
  .react-aria-TabList {
    display: flex;
  
    &[data-orientation=horizontal] {
      border-bottom: 10px solid var(--highlight-background);
    }
  }
  
  .react-aria-Tab {
    margin-right: 5px;
    background-color: #212529;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: normal;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: default;
    outline: none;
    position: relative;
    color: #FFF;
    transition: color 200ms;
    --border-color: transparent;
    forced-color-adjust: none;
    padding: 0.75rem 2rem 0.75rem 1.25rem;
    .tab_icon{
        margin-right: 1.25rem;
        svg{
            width: 20px;
            height: auto;
        }
    }
  
    &[data-hovered],
    &[data-focused] {
        color: #EAEAEA;
    }

    &[aria-selected="false"]{

    }
  
    &[data-selected] {
        background-color: var(--highlight-background);

        &::after, &::before{
          content: url("data:image/svg+xml,%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.25V14.25H14C6.26801 14.25 0 7.98201 0 0.25Z' fill='%233AA2AD'/%3E%3C/svg%3E%0A");            
          z-index: 2;
          display: inline-block;
          width: 25px;
          height: 14px;
          position: absolute;
          bottom: 0;
        }

        &::after{
          transform: translateX(100%);
          right: 0;
        }
        
        &::before{
          transform: translateX(-100%) scale(-1, 1);
          bottom: 0;
          left: 0;
        }

      &:first-child::before{
        content: none;
      }
    }
  
    &[data-disabled] {
      color: var(--text-color-disabled);
      &[data-selected] {
        --border-color: var(--text-color-disabled);
      }
    }
  
    &[data-focus-visible]:after {
      content: '';
      position: absolute;
      inset: 4px;
      border-radius: 4px;
      border: 2px solid var(--focus-ring-color);
    }
  }
  
  .react-aria-TabPanel {
    /* margin-top: 4px;
    padding: 10px; */
    border-radius: 4px;
    outline: none;
  
    &[data-focus-visible] {
      outline: 2px solid var(--focus-ring-color);
    }
  }

  .react-aria-Tabs {
    &[data-orientation=vertical] {
      flex-direction: row;
    }
  }
  
  .react-aria-TabList {
    &[data-orientation=vertical] {
      flex-direction: column;
      border-inline-end: 1px solid gray;
  
      .react-aria-Tab {
        border-inline-end: 3px solid var(--border-color, transparent);
      }
    }
  }