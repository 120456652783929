.react-aria-DropZone {
  color: var(--text-color);
  background: transparent;
  border: 1px dashed var(--border-color);
  /* @apply bg-mr-gray-200; */
  forced-color-adjust: none;
  border-radius: 4px;
  appearance: none;
  vertical-align: middle;
  font-size: 1.2rem;
  text-align: center;
  margin: 0;
  outline: none;
  padding: 24px 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  &.type-error{
    border-color: red;
  }

  &[data-focus-visible],
  &[data-drop-target] {
    outline: 2px solid var(--focus-ring-color);
    outline-offset: -1px;
  }

  &[data-drop-target] {
    background: var(--highlight-overlay);
  }

  .react-aria-Button{
      font-size: 0.75rem;
      .dropzone_icon{
          margin: auto;
          width: 30px;
          height: 30px; 
          margin-bottom: .5rem;
          @screen lg{
              width: 40px;
              height: 40px;
          }
      }
      span{
        @apply text-mr-cyan-400;
      }
  }
}

.loading-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  margin-top: .5rem;
}


.custom-loader {
  width: 30px;
  height: 30px;
  display: grid;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side,#0000 40%,#EAEAEA 41%);
  background: linear-gradient(0deg ,#000 50%,#EAEAEA 0) center/2px 100%,
    linear-gradient(90deg,#000 50%,#EAEAEA 0) center/100% 2px;
  background-repeat: no-repeat;
  animation: spin 1s infinite steps(12);
}

.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.custom-loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes spin {
  100% {
    transform: rotate(1turn)
  }
}