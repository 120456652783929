.react-aria-ToggleButton {
  color: var(--text-color);
  background: var(--button-background);
  forced-color-adjust: none;
  border-radius: 4px;
  appearance: none;
  vertical-align: middle;
  font-size: 1rem;
  text-align: center;
  margin: 0;
  outline: none;
  padding: 6px 10px;

  &[data-pressed] {
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 0.1);
    background: var(--button-background-pressed);
    border-color: var(--border-color-pressed);
  }

  &[data-selected] {
    background: var(--highlight-background);
    border-color: var(--highlight-background);
    color: var(--highlight-foreground);

    &[data-pressed] {
      background: var(--highlight-background-pressed);
      border-color: var(--highlight-background-pressed);
    }
  }

  &[data-focus-visible] {
    outline: 2px solid var(--focus-ring-color);
    outline-offset: 2px;
  }
}