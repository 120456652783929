.react-aria-RadioGroup {
    .react-aria-RadioGroup-container {
        position: relative;
        &::before {
            border-radius: 100px;
            background-color: theme("colors.mr.cyan.400");
            content: "";
            display: block;
            width: calc(100% / var(--radio-count, 2));
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            transform: translateX(0%);
            transition: transform 0.15s ease-in-out;
            opacity: 0;

            .active & {
                opacity: 1;
            }
        }

        & > span {
            position: relative;
            z-index: 1;
            transition: color 0.15s ease-in-out;
        }

        &.selected {
            &::before {
                transform: translateX(0%);
            }
        }

        .active {
            color: white;
        }
    }

    .react-aria-RadioGroup-container.active::before {
        opacity: 1;
        transform: translateX(calc(100% * var(--radio-selected, 1)));
    }
}
