.react-aria-Switch {
    .react-aria-Switch-container {
        position: relative;
        &::before {
            border-radius: 100px;
            background-color: theme("colors.mr.cyan.400");
            content: "";
            display: block;
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            transform: translateX(100%);
            transition: transform 0.15s ease-in-out;
        }

        & > span {
            position: relative;
            z-index: 1;
            transition: color 0.15s ease-in-out;
        }

        &.selected {
            &::before {
                transform: translateX(0%);
            }
        }

        .active {
            color: white;
        }
    }
}
