/* color themes for dark and light modes, generated with Leonardo.
 * Light: https://leonardocolor.io/theme.html?name=Light&config=%7B%22baseScale%22%3A%22Gray%22%2C%22colorScales%22%3A%5B%7B%22name%22%3A%22Gray%22%2C%22colorKeys%22%3A%5B%22%23000000%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%22-1.12%22%2C%221.45%22%2C%222.05%22%2C%223.02%22%2C%224.54%22%2C%227%22%2C%2210.86%22%5D%2C%22smooth%22%3Afalse%7D%2C%7B%22name%22%3A%22Purple%22%2C%22colorKeys%22%3A%5B%22%235e30eb%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%22-1.12%22%2C%221.45%22%2C%222.05%22%2C%223.02%22%2C%224.54%22%2C%227%22%2C%2210.86%22%5D%2C%22smooth%22%3Afalse%7D%2C%7B%22name%22%3A%22Red%22%2C%22colorKeys%22%3A%5B%22%23e32400%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%22-1.12%22%2C%221.45%22%2C%222.05%22%2C%223.02%22%2C%224.54%22%2C%227%22%2C%2210.86%22%5D%2C%22smooth%22%3Afalse%7D%5D%2C%22lightness%22%3A98%2C%22contrast%22%3A1%2C%22saturation%22%3A100%2C%22formula%22%3A%22wcag2%22%7D */
:root {
    --background-color: #f8f8f8;
    --gray-50: #ffffff;
    --gray-100: #d0d0d0;
    --gray-200: #afafaf;
    --gray-300: #8f8f8f;
    --gray-400: #717171;
    --gray-500: #555555;
    --gray-600: #393939;
    --purple-100: #d5c9fa;
    --purple-200: #b8a3f6;
    --purple-300: #997cf2;
    --purple-400: #7a54ef;
    --purple-500: #582ddc;
    --purple-600: #3c1e95;
    --red-100: #f7c4ba;
    --red-200: #f29887;
    --red-300: #eb664d;
    --red-400: #de2300;
    --red-500: #a81b00;
    --red-600: #731200;
    --highlight-hover: rgb(0 0 0 / 0.07);
    --highlight-pressed: rgb(0 0 0 / 0.15);
}

/* Semantic colors */
:root {
    --focus-ring-color: theme("colors.mr.cyan.400");
    --text-color: var(--gray-600);
    --text-color-base: var(--gray-500);
    --text-color-hover: var(--gray-600);
    --text-color-disabled: var(--gray-200);
    --text-color-placeholder: var(--gray-400);
    --link-color: var(--purple-500);
    --link-color-secondary: var(--gray-500);
    --link-color-pressed: var(--purple-600);
    --border-color: var(--gray-300);
    --border-color-hover: var(--gray-400);
    --border-color-pressed: var(--gray-400);
    --border-color-disabled: var(--gray-100);
    --field-background: var(--gray-50);
    --field-text-color: var(--gray-600);
    --overlay-background: var(--gray-50);
    --button-background: var(--gray-50);
    --button-background-pressed: var(--background-color);
    /* these colors are the same between light and dark themes
   * to ensure contrast with the foreground color */
    --highlight-background: theme(
        "colors.mr.cyan.400"
    ); /* purple-300 from dark theme, 3.03:1 against background-color */
    --highlight-background-pressed: theme(
        "colors.mr.cyan.300"
    ); /* purple-200 from dark theme */
    --highlight-background-invalid: #cc2000; /* red-300 from dark theme */
    --highlight-foreground: white; /* 5.56:1 against highlight-background */
    --highlight-foreground-pressed: #ddd;
    --highlight-overlay: rgb(from theme("colors.mr.cyan.400") r g b / 15%);
    --invalid-color: theme("colors.mr.orange.400");
    --invalid-color-pressed: theme("colors.mr.orange.400");
}

/* Windows high contrast mode overrides */
@media (forced-colors: active) {
    :root {
        --background-color: Canvas;
        --focus-ring-color: Highlight;
        --text-color: ButtonText;
        --text-color-base: ButtonText;
        --text-color-hover: ButtonText;
        --text-color-disabled: GrayText;
        --text-color-placeholder: ButtonText;
        --link-color: LinkText;
        --link-color-secondary: LinkText;
        --link-color-pressed: LinkText;
        --border-color: ButtonBorder;
        --border-color-hover: ButtonBorder;
        --border-color-pressed: ButtonBorder;
        --border-color-disabled: GrayText;
        --field-background: Field;
        --field-text-color: FieldText;
        --overlay-background: Canvas;
        --button-background: ButtonFace;
        --button-background-pressed: ButtonFace;
        --highlight-background: Highlight;
        --highlight-background-pressed: Highlight;
        --highlight-background-invalid: LinkText;
        --highlight-foreground: HighlightText;
        --highlight-foreground-pressed: HighlightText;
        --invalid-color: LinkText;
        --invalid-color-pressed: LinkText;
    }
}
