.react-aria-ComboBox {
    color: var(--text-color);
  
    .react-aria-Input {
      margin: 0;
      width: 100%;
      font-size: 1.072rem;
      background: var(--field-background);
      color: var(--field-text-color);
      border: 1px solid var(--border-color);
      border-radius: 6px;
      padding: 0.286rem 2rem 0.286rem 0.571rem;
      vertical-align: middle;
  
      &[data-focused] {
        outline: none;
        outline: 2px solid var(--focus-ring-color);
        outline-offset: -1px;
      }
    }

      .react-aria-Button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border-left-width: 1px;
        border-style: solid;
        border-left-color: rgb(170 170 170 / var(--tw-border-opacity));
        padding-left: 0.75rem;
        padding-right: 0.75rem;
      }
    /* .react-aria-Button {
      background: var(--highlight-background);
      color: var(--highlight-foreground);
      forced-color-adjust: none;
      border-radius: 4px;
      border: none;
      margin-left: -1.714rem;
      width: 1.429rem;
      height: 1.429rem;
      padding: 0;
      font-size: 0.857rem;
      cursor: default;
  
      &[data-pressed] {
        box-shadow: none;
        background: var(--highlight-background);
      }
    } */
  }
  
  .react-aria-Popover[data-trigger=ComboBox] {
    border: 1px solid var(--border-color);
    max-width: var(--trigger-width);
    .react-aria-ListBox {
      background-color: white;
      display: block;
      max-height: inherit;
      min-height: unset;
      border: none;
      width: unset;
      .react-aria-Header {
        padding-left: 1.571rem;
      }
    }
  
    .react-aria-ListBoxItem {
      padding: 0.286rem 0.571rem 0.286rem 1.571rem;
  
      &[data-focus-visible] {
        outline: none;
      }
  
      &[data-selected] {
        font-weight: 600;
        background: unset;
        color: var(--text-color);
  
        &::before {
          content: '✓';
          content: '✓' / '';
          alt: ' ';
          position: absolute;
          top: 4px;
          left: 4px;
        }
      }
  
      &[data-focused],
      &[data-pressed] {
        background: var(--highlight-background);
        color: var(--highlight-foreground);
      }
    }
  }